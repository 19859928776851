@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
// $body-background-color: $beige-lighter;

// Custom
$input-border-color: #e5e5e5;
$input-shadow: none;
$input-radius: 0;
$input-focus-border-color: #5e7cfe;
$input-focus-box-shadow-size: 0 0 0 0;

$tabs-link-padding: 20px 10px 10px;
$tabs-border-bottom-color: #e5e5e5;
$tabs-link-color: #888;
$tabs-link-active-border-bottom-color: #5e7cfe;
$tabs-link-active-color: #5e7cfe;

$table-color: #000;
$table-cell-border: 1px solid #e0e0e0;
$table-head-background-color: #e9eef5;
$table-head-cell-color: #000;
$table-cell-heading-color: #000;
$table-foot-cell-color: #000;

$button-text-color: #666666;
$button-focus-box-shadow-color: rgba($link, 0);
$button-text-hover-background-color: transparent;

// Import only what you need from Bulma
@import '../node_modules/bulma';
